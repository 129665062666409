.App {
  text-align: center;
}

body{
  align-items: center;
}

.form-position {
  margin-top: 70px;
  margin-left: 10vw;
  grid-column: 1;
  grid-row: 3/3;
  justify-self: center;
}

.home-position{
  align-items: center;
}

@media screen and (min-width: 551px) {
  .form-position {
    margin-top: 70px;
    margin-left: 13vw;
    grid-column: 1;
    grid-row: 3/3;
    justify-self: center;
  }
}

@media screen and (min-width: 1001px) {
  .form-position {
    margin-top: 5vw;
    margin-left: 35vw;
    justify-self: center;
  }
}