input[type="submit"],
select,
button {
  -webkit-appearance: none;
}

.login-container{
    align-items: center;
}

.message {
  position: absolute;
  width: 80%;
}

.form {
  display: auto;
  width: 65vw;
  flex-direction: row;
  background-color: #212529;
  border-radius: 6px;
  padding: 2rem;
  margin-bottom: 10%;
  font-family: "Poppins", sans-serif;
  filter: drop-shadow(2px 3px 4px rgb(0, 0, 0, 0.5));
}

.contact-form {
  display: flex;
  flex-direction: column;
  font-weight: 70;
}

input {
  padding-left: 10px;
  padding-top: 5px;
  height: 7vw;
  background-color: #ffffff;
  border-radius: 5px;
}

::placeholder {
  font-size: 3vw;
  font-weight: 60;
}

button[type="submit"] {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 4vw;
  background-color: #28a745;
  border-radius: 6px;
  border: 0;
  width: 100%;
  height: 6vh;
  filter: drop-shadow(0px 4px 4px rgb(0, 0, 0, 25));
  color:#ffffff
}

button[type="submit"]:active {
  margin-top: 1.8rem;
  filter: drop-shadow(0px 0px 0px rgb(0, 0, 0));
  margin-bottom: 1 rem;
  color:#ffffff
}


@media screen and (min-width: 551px) {
      .form {
        text-align: center;
        justify-self: center;
      }

      .contact-form {
        display: flex;
        flex-direction: column;
        font-weight: 75;
      }
      
      input {
        padding-left: 10px;
        height: 3vw;
        padding-top: 5px;
        background-color: #ffffff;
        border-radius: 5px;
      }
      
      ::placeholder {
        font-size: 2vw;
        font-weight: 60;
      }
      
      button[type="submit"] {
        margin-top: 1.5rem;
        margin-bottom: 1rem;
        font-size: 2vw;
        background-color: #28a745;
        border-radius: 6px;
        border: 0;
        height: 6vh;
        filter: drop-shadow(0px 4px 4px rgb(0, 0, 0, 25));
        color:#ffffff;
        height: 5vh;
      }
      
      button[type="submit"]:active {
        margin-top: 1.8rem;
        filter: drop-shadow(0px 0px 0px rgb(0, 0, 0));
        margin-bottom: 1 rem;
        color:#ffffff
      }
}

@media screen and (min-width: 1001px) {
    .form {
    text-align: center;
    justify-self: center;
    width: 40%;
  }

  input,
  select {
    align-items: center;
    height: 2vw;
    font-size: 1vw;
  }

  ::placeholder {
    font-size: 1vw;
  }

  button[type="submit"] {
    margin-left: 8px;
    font-size: 1.1vw;
    font-weight: 600;
    height: 5vh;
    width: 25vw;
    justify-self: center;
    color:#ffffff
  }

  .message {
    padding-top: 0%;
    padding-left: 5%;
  }
}
