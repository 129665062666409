
.accordion_item {
  border-top: 1px solid #9f9f9f;
}

.accordion_user {
  border-top: 1px solid #9f9f9f;
  padding: 10px;
  width: 100%;
}

.button {
  font-size: 16px;
  background-color: #008593;
  color: #fff;
  text-align: left;
  font-weight: 700;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 18px 8px;
  cursor: pointer;
  border: none;
}

.control {
  font-size: 20px;
}

.answer {
    background-color: #f7f7f7;
    padding: 20px;
  }

/* activate toggle */
.accordion_item.active .button {
  background-color: #105057;
}

  .answer_wrapper {
    height: 0;
    overflow: hidden;
    transition: height ease 0.2s;
  }
  
  .accordion_item {
    border-top: 1px solid #9f9f9f;
  }

  /* activate toggle */
  /* .answer_wrapper.open {
    height: auto;
  } */