.container{
    align-items: center;
}

.box-title{
    padding: 1rem;
    margin-left: 460px;
    box-shadow: 0 0 1rem #00000025 inset;
    border-radius: 10px;
    text-align: center;
    width: 350px;
}

.user{
    padding: 0.5rem;
    padding-left: 3rem;
    margin-left: 4rem;
    margin-right: 4rem;
    box-shadow: 0 0 1rem #00000025 inset;
    border-radius: 10px;
    text-align: left;
    font-size: 25
    px;
}

.grid-responsive{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
}

.collapse-css-transition {
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);
    overflow: hidden;
  }
/*  */
/*  */
/*  */
.heading {
    font-size: 23px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

.container {
    max-width: 800px;
    margin: 3rem auto 4rem;
    padding: 0 5px;
}

.accordion {
    list-style: none;
  }

  .accordion_item_home {
    border-top: 1px solid #9f9f9f;
  }

button{
    width: 25%;
    height: 70%;
    justify-self: flex-end;
    margin-top: 2%;
}

  .button {
    font-size: 16px;
    background-color: #008593;
    border-radius: 15px;
    color: #fff;
    text-align: left;
    font-weight: 700;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 18px;
    cursor: pointer;
    border: none;
  }

  .control {
    font-size: 20px;
  }