.topbar-container {
  background: #212529;
  height: 58px;
  display: flex;
  align-items: center;
  font-family: "Inter", sans-serif;
  width: 100%;
}

.navbar-brand{
  max-width: 720px;
  color: #fff;
  font-size: 1.25rem;
  padding-left: 10vw;
}

@media screen and (min-width: 1001px) {
  .navbar-brand{
    font-size: 1.25rem;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    display: flex;
  }
}