.container-user{
    width: 100%;
}
.user-name{
    padding-left: 5px;
    font-weight: 800;
}

.user-phone{
    padding-left: 25px;
}

.user-status{
    margin-left: 25px;
}

.status-button{
    padding-left: 0px;
}

@media screen and (min-width: 551px) {
    .user-name{
        margin-left: 25px;
        font-weight: 800;
    }

    .user-phone{
        margin-left: 75px;
    }

    .user-status{
        margin-left: 45px;
    }

    .status-button{
        padding-left: 15px;
    }
}